.boost-card {
    border: 1px solid var(--color-gold);
}

.boost-card > .boost-card__top-block {
    border-bottom: 1px solid var(--color-gold);
    display: flex;
    flex-direction: row;
}

.boost-card__image {
    --boost-card__image-size: 64px;
    min-width: var(--boost-card__image-size);
    width: var(--boost-card__image-size);
    height: var(--boost-card__image-size);
    border-radius: 8px;
    overflow: hidden;
}

.boost-card__image > img {
    width: 100%;
}

.boost-card__purchase-button {
    border-left: 1px solid var(--color-gold);
    justify-content: space-between;
}
