@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'BehrensAntiqua';
    src: url('fonts/BehrensAntiqua-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
}

body {
    background-color: black;
    --tg-theme-header-bg-color: black;
    margin: 0;
    font-family: 'BehrensAntiqua', "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #e5e7eb;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --color-gold: #ffbf00;
    --color-tg-blue: #54A9EB;
    --appearance-size: 48px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-gray-primary {
    background-color: #222428;
}

.bg-gold {
    background-color: var(--color-gold);
}

.text-gold {
    color: var(--color-gold);
    -webkit-text-stroke: 0.01rem black;
}

.bg-tg-blue {
    background-color: var(--color-tg-blue);
}

.border-gold {
    border-color: var(--color-gold);
}

.border-gray-primary {
    border-color: var(#222428);
}

.bg-gray-secondary {
    background-color: #2f302f;
}

.clickable:active {
    transform: scale(0.95);
}

.modal-window {
    height: calc(100vh - 4rem);
    width: calc(100% - 2rem);;
}

.modal-window .content {
    max-height: calc(100vh - 6rem);
    height: calc(100vh - 6rem);
    overflow: hidden;
}

.chat {
    font-family: "Shantell Sans", cursive;
}

.loader {
    border: 2px solid var(--color-gold);
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

.progress-bar {
    transition: width 1s linear;
}

.home {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    /*background-image: url("./assets/bg-v.png");*/
}

.shake {
    animation: shake 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.pulse {
    animation: pulse 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.appearance-top {
    animation: appearance-top .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.appearance-right {
    animation: appearance-right .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.appearance-bottom {
    animation: appearance-bottom .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.appearance-left {
    animation: appearance-left .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

.thief-hand {
}

.thief-sign {
    opacity: 0;
}

.thief-loader > .thief-hand {
    animation: thief-hand 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.thief-loader > .thief-sign {
    animation: thief-sign 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

.range-slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: whitesmoke;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 26px;
    width: 26px;
    background-color: #ffffff;
    border: 4px solid #052e16;
    border-radius: 50%;
    position: relative;
    z-index: 3;
}

@keyframes thief-hand {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    10% {
        transform: scale(0);
        opacity: 0;
    }
    20% {
        transform: scale(0);
        opacity: 0;
    }
    30% {
        transform: scale(0);
        opacity: 0;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0);
        opacity: 0;
    }
    60% {
        transform: scale(0);
        opacity: 0;
    }
    70% {
        transform: scale(0);
        opacity: 0;
    }
    80% {
        transform: scale(1);
        opacity: 1;
    }
    90% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes thief-sign {
    0% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    10% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    20% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    30% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    40% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    50% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    60% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    70% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    80% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    90% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
    100% {
        transform: scale(0) rotate(720deg);
        opacity: 0;
    }
}

@keyframes shake {
    0% {
        transform: translateX(0) rotate(0deg);
    }
    10% {
        transform: translateX(-10px) rotate(-10deg);
    }
    20% {
        transform: translateX(10px) rotate(10deg);
    }
    30% {
        transform: translateX(-10px) rotate(-10deg);
    }
    40% {
        transform: translateX(10px) rotate(10deg);
    }
    50% {
        transform: translateX(-10px) rotate(-10deg);
    }
    60% {
        transform: translateX(0) rotate(0deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes appearance-top {
    0% {
        transform: translateY(calc(var(--appearance-size) * -1));
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes appearance-bottom {
    0% {
        transform: translateY(var(--appearance-size));
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes appearance-right {
    0% {
        transform: translateX(var(--appearance-size));
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes appearance-left {
    0% {
        transform: translateX(calc(var(--appearance-size) * -1));
    }
    100% {
        transform: translateX(0px);
    }
}

